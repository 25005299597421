import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import './FeedHeader.scss';
import logo from './img/logo-dark.png';

class Header extends Component {

    render() {
        let {userRole} = this.props;
        return (
            <section className="feed-header container">
                <Link to="/">
                    <img className="feed-header__logo" src={logo} alt="logo" />
                </Link>
                {userRole === "admin" ? <Link to="/admin"><FontAwesomeIcon icon={faCog} className="feed-header__admin-btn"/></Link> : null}
                
            </section>
            
        )
    }
}

export default Header;